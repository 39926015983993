<template>
    <component :is="componentType" v-bind="stat" />
</template>

<script>
import * as components from '@/components/stats'

export default {
    components: { ...components },
    props: {
        stat: {
            type: Object,
            required: true,
        },
    },
    computed: {
        componentType() {
            const mapping = {
                statsComparison: 'StatComparison',
                responseSummary: 'ResponseSummary',
            }
            return mapping[this.stat.type]
        },
    },
}
</script>

<style scoped></style>
