<template>
    <v-row>
        <v-col cols="12" class="d-flex justify-space-between">
            <span class="text-overline">{{ title }}</span>
            <v-tooltip v-if="subtitle" left :open-on-hover="false">
                <template #activator="{ on }">
                    <v-btn
                        icon
                        @click="on.click"
                        @blur="on.blur"
                        retain-focus-on-click
                    >
                        <v-icon>mdi-information</v-icon>
                    </v-btn>
                </template>
                <div class="font-italic" v-html="subtitle" />
            </v-tooltip>
        </v-col>
        <v-col cols="6" class="d-flex flex-column align-center justify-center">
            <span>Last month</span>
            <v-progress-circular
                :rotate="-90"
                :value="0"
                :size="80"
                :width="10"
                color="secondary"
                class="ma-4"
                ><div class="text-h6 text--secondary">
                    {{ data.previous }}
                </div></v-progress-circular
            >
        </v-col>
        <v-col cols="6" class="d-flex flex-column align-center justify-center">
            <span>This month</span>
            <v-progress-circular
                :rotate="-90"
                :value="currentPercentage"
                :size="80"
                :width="10"
                :color="currentColour"
                class="ma-4"
                ><div class="text-h6 text--secondary">
                    {{ data.current }}
                </div></v-progress-circular
            >
            <transition name="pulse" mode="out-in">
                <span class="level-up text-h6 primary--text" :key="data.current"
                    >+1</span
                >
            </transition>
        </v-col>
    </v-row>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: true,
        },
        subtitle: {
            type: String,
            default: '',
        },
        data: {
            type: Object,
            required: true,
        },
    },
    computed: {
        currentPercentage() {
            return (this.data.current / this.data.previous) * 100
        },
        currentColour() {
            return this.data.previous > this.data.current ? 'primary' : 'accent'
        },
    },
}
</script>

<style scoped>
.level-up {
    position: absolute;
    opacity: 0;
}
.pulse-enter {
    transform: scale(1) translateY(0px);
    color: orange;
    opacity: 1;
}
.pulse-enter-active {
    transition: all 2s ease-out;
}
.pulse-enter-to {
    transform: scale(3) translateY(-1rem);
    opacity: 0;
}
.pulse-leave {
    opacity: 1;
}
.pulse-leave-active {
    transition: all 0.2s;
}
.pulse-leave-to {
    transform: scale(0.1);
    opacity: 0;
}
</style>
