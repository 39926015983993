<template>
    <v-row dense>
        <v-col cols="12" class="d-flex justify-space-between">
            <span class="mr-2 text-overline">
                {{ title }}
            </span>
            <v-spacer />
            <v-tooltip v-if="subtitle" left :open-on-hover="false">
                <template #activator="{ on }">
                    <v-btn
                        icon
                        @click="on.click"
                        @blur="on.blur"
                        retain-focus-on-click
                    >
                        <v-icon>mdi-information</v-icon>
                    </v-btn>
                </template>
                <div class="font-italic" v-html="subtitle" />
            </v-tooltip>
        </v-col>
        <v-col cols="12" class="d-flex flex-wrap justify-center">
            <v-sheet
                min-width="200"
                max-width="400"
                class="flex-grow-1 mb-4"
                v-for="[category, data] of Object.entries(dataByCategory)"
                :key="category"
            >
                <single-response-summary :category="category" v-bind="data" />
            </v-sheet>
        </v-col>
    </v-row>
</template>

<script>
export default {
    components: {
        SingleResponseSummary: () =>
            import('@/components/stats/components/SingleResponseSummary'),
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        subtitle: {
            type: String,
            required: false,
        },
        data: {
            type: Array,
            required: true,
        },
    },
    computed: {
        dataByCategory() {
            console.debug('Calculating dataByCategory')
            // Group the array of data points by category.
            let data = this.data.reduce((accumulator, currentValue) => {
                let { category, average, count } = currentValue
                if (!average) {
                    // `average` can be null.
                    average = 0
                }
                if (!(category in accumulator)) {
                    accumulator[category] = {
                        average: [average],
                        count: [count],
                    }
                    return accumulator
                }
                accumulator[category].average = [
                    ...accumulator[category].average,
                    average,
                ]
                accumulator[category].count = [
                    ...accumulator[category].count,
                    count,
                ]
                return accumulator
            }, {})
            return data
        },
    },
}
</script>

<style scoped></style>
